export default function useAssets (asset: string) {
  const assets: Record<string, object> = import.meta.glob('/assets/images/**', {eager: true});
  
  const getAssetUrl = () => {
    if (assets[asset]) {
      return assets[asset].default;
    }
  };
  
  return getAssetUrl();
}